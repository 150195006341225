<template>
  <div class="container is-marginless is-max-desktop">
    <div class="level mb-6">
      <div class="level-left level-title is-align-items-baseline">
        <div class="level-item">
          <h1 class="title is-1 has-text-weight-light">{{ chapterIndex + 1 }}</h1>
        </div>
        <div class="level-item">
          <h1 class="title is-underlined has-text-weight-normal">{{ chapter.title }}</h1>
        </div>
      </div>
    </div>
    <markdown-viewer :markdown="chapterMarkdown"></markdown-viewer>
  </div>
</template>

<script>
import { ref } from 'vue'
import { useMeta } from 'vue-meta'
import WebFileService from '@/assets/js/WebFileService.js';
import MarkdownViewer from '@/components/MarkdownViewer.vue'

const beforeRouteEnterOrUpdate = (to) => {
  function isValid(slug) {
    const manifest = WebFileService.getInstance().loadManifest();
    if (manifest === null || !manifest.chapters) return false;
    return manifest.chapters.some(c => c.slug === slug);
  }

  if (!isValid(to.params.slug)) {
    // Redirect to 404, but keep the same URL, query, and hash
    return {
      name: '404NotFound',
      params: { pathMatch: to.path.split('/').slice(1) },
      query: to.query,
      hash: to.hash,
    }
  }
}

export default {
  name: 'ViewChapter',
  components: {
    MarkdownViewer
  },
  props: {
    manifest: Object,
    chapterIndex: Number
  },
  data() {
    return {
      chapterMarkdown: null
    }
  },
  setup() {
    const metaInfo = useMeta({ });
    const meta = ref(metaInfo.meta);
    return { meta };
  },
  watch: {
    chapterIndex: {
      immediate: true,
      handler(newVal) {
        if (newVal < 0) return;

        this.meta.title = this.chapter.title;
        // Load the chapter Markdown file
        const path = `/booksrc/chapters/${this.chapter.id}.md`;
        const wf = WebFileService.getInstance();
        this.chapterMarkdown = wf.loadFile(path);
      }
    }
  },
  computed: {
    chapter() {
      return this.manifest.chapters[this.chapterIndex];
    }
  },
  beforeRouteEnter: beforeRouteEnterOrUpdate,
  beforeRouteUpdate: beforeRouteEnterOrUpdate,
}
</script>

<style lang="scss" scoped>
.level-title {
  .level-item:not(:last-child) {
    @include tablet {
      margin-right: 2rem;
    }

    @include mobile {
      margin-bottom: 2rem;
    }
  }
}
</style>