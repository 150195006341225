<template>
  <div class="level">
    <div class="level-left">
      <div class="level-item">
        <router-link :to="'/' + previousPage.slug" v-if="previousPage" class="button is-light">&lt; {{ previousPage.title }}</router-link>
        <router-link to="/" v-if="!previousPage && currentChapterIndex !== -1" class="button is-light">&lt; Home</router-link>
      </div>
    </div>
    <div class="level-right">
      <div class="level-item">
        <router-link :to="'/' + nextPage.slug" v-if="nextPage" class="button is-light">{{ currentChapterIndex === -1 ? "First Chapter: " : "Next Chapter: " }}{{ nextPage.title }} &gt;</router-link>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Pagination',
  props: {
    chapters: Array,
    currentChapterIndex: Number
  },
  data() {
    return { }
  },
  computed: {
    currentPage() {
      if (this.currentChapterIndex === -1) return null;
      return this.chapters[this.currentChapterIndex];
    },
    nextPage() {
      if (this.currentChapterIndex === this.chapters.length - 1) return null;
      else return this.chapters[this.currentChapterIndex + 1];
    },
    previousPage() {
      if (this.currentChapterIndex === 0) return null;
      else return this.chapters[this.currentChapterIndex - 1];
    },
  }
}
</script>

<style lang="scss" scoped>
  
</style>
