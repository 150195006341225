<template>
  <div class="home">
    <div v-if="loading">
      <h1>Loading...</h1>
    </div>
    <div v-else>
      <div class="columns">
        <div class="side-nav is-hidden-mobile">
          <div class="is-flex is-flex-direction-column height-100-percent">
            <div class="title is-4"><router-link to="/" class="has-text-grey-dark">{{ manifest.title }}</router-link></div>
            <div class="subtitle is-6">{{ manifest.subtitle }}</div>
            <div class="menu is-flex-grow-1">
              <ul class="menu-list">
                <li v-for="(chapter, i) in manifest.chapters" :key="chapter.id">
                  <router-link :class="{ 'is-active': chapter.slug === currentSlug }" :to="'/' + chapter.slug">
                    <div class="columns is-vcentered">
                      <div class="column">{{ chapter.title }}</div>
                      <div class="column is-narrow">{{ i + 1 }}</div>
                    </div>
                  </router-link>
                </li>
              </ul>
            </div>
            <div class="mt-5">
              <div>Copyright &copy; 2022 {{ manifest.copyright.holder }}</div>
              <div>{{ manifest.copyright.license }}</div>
            </div>
          </div>
        </div>
        <div class="column p-0">
          <router-link to="/" v-if="currentChapterIndex > -1" class="is-block is-hidden-tablet has-text-dark has-background-primary-light py-2 px-4">
            <h3 class="title is-4">{{ manifest.title }}</h3>
          </router-link>
          <section class="section">
            <router-view :manifest="manifest" :chapterIndex="currentChapterIndex"></router-view>
            <div class="container is-marginless is-max-desktop">
              <pagination class="mt-6" :chapters="manifest.chapters" :currentChapterIndex="currentChapterIndex" />
            </div>
          </section>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
import Pagination from '@/components/Pagination.vue'
import WebFileService from '@/assets/js/WebFileService.js'

export default {
  name: 'Home',
  components: {
    Pagination
  },
  data() {
    return {
      loading: true,
      manifest: null,
    }
  },
  created() {
    const manifest = WebFileService.getInstance().loadManifest();
    if (manifest === null) {
      // Book manifest file does not exist, redirect to "connect" page
      this.$router.replace({ name: 'Connect' });
    } else {
      this.manifest = manifest;
      this.loading = false;
    }
  },
  methods: {
  },
  computed: {
    currentSlug() {
      return this.$route.path === '/' ? null : this.$route.path.substring(1)
    },
    currentChapterIndex() {
      for (let i = 0; i < this.manifest.chapters.length; i++) {
        if (this.manifest.chapters[i].slug === this.currentSlug) return i;
      }
      return -1;
    }
  },
}
</script>

<style lang="scss" scoped>
.columns {
  margin: 0;
}

.side-nav {
  background: #eee;
  position: sticky;
  display: inline-block;
  vertical-align: top;
  height: 100vh;
  min-width: 25rem;
  max-width: 25rem;
  overflow: hidden;
  top: 0;
  bottom: 0;
  padding: 1.5rem;
  box-shadow: 0px 0px 15px 0px $grey;

  @include touch {
    padding: 1.5rem 0;
    min-width: 23rem;
    max-width: 23rem;

    .title, .subtitle {
      margin-left: 1rem;
      margin-right: 1rem;
    }
  }

  .menu {
    max-height: 100%;
    overflow-y: auto;

    @include touch {
      padding: 0.5rem;
    }

    .menu-list .column {
      overflow-x: hidden;
      text-overflow: ellipsis;
      padding: 0 0.25rem;
    }
  }
}
</style>