<template>
  <div class="container is-marginless is-max-desktop">
    <h1 class="title is-1 has-text-weight-light">{{ manifest.title }}</h1>
    <h2 class="subtitle is-4">{{ manifest.subtitle }}</h2>
    <section class="section">
      <h1 class="title is-4">Contents</h1>
      <router-link class="contents-link" v-for="(chapter, i) in manifest.chapters" :key="chapter.id" :to="'/' + chapter.slug">
        <div class="columns is-mobile">
          <div class="column is-narrow">
            <h4 class="title is-5">{{ i + 1 }}</h4>
          </div>
          <div class="column">
            <h3 class="title is-5 mb-1">{{ chapter.title }}</h3>
            <p class="has-text-grey-dark">{{ chapter.subtitle }}</p>
          </div>
        </div>
      </router-link>
    </section>
  </div>
</template>

<script>
import { useMeta } from 'vue-meta'

export default {
  name: 'ViewHome',
  components: {
  },
  props: {
    manifest: Object
  },
  data() {
    return { }
  },
  setup(props) {
    useMeta({
      title: props.manifest.title
    });
  },
}
</script>

<style lang="scss" scoped>
.level-title {
  .level-item:not(:last-child) {
    @include tablet {
      margin-right: 2rem;
    }

    @include mobile {
      margin-bottom: 2rem;
    }
  }
}

.contents-link {
  display: block;
  padding: 0.25rem;
  border-radius: 2px;

  &:hover {
    background-color: $white-ter;
    .column:nth-child(2) > :first-child {
      text-decoration: underline;
    }
  }
}
</style>